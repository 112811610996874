import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from './types';
import { AxiosService } from 'api/axiosService';

export type Location = {
  id: number;
  location: string;
};

type LocationsResult = {
  locations: Location[];
  restricted_locations: Location[];
};

export const useLocations = (options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: ['locations'],
    queryFn: async () =>
      await AxiosService.get<ApiResponse<LocationsResult>>('/sites/all_locations'),
    retry: 0,
    gcTime: 0,
    ...options,
  });
};
